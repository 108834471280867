<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row
          ><v-col>
            <p>
              <span>Purchase Your Insurance (Credit Card).</span><br /><br />
              <span
                >Please enter you credit card details below and then click
                'Process My Payment' to pay for you insurance.</span
              ><br /><br />
              Your card will be charged the following amount ${{ item.amount }}
              (inc. GST) plus any applicable surcharge.
            </p></v-col
          ></v-row
        >
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.amount"
              label="Payment Amount"
              readonly
              dense
              outlined
              :rules="amountRule"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.name"
              label="Card Holder Name"
              dense
              outlined
              :rules="[(v) => !!v || 'Card Holder Name is required']"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.cardNumber"
              type="number"
              dense
              outlined
              label="Card Number"
              :rules="[(v) => !!v || 'Card Number is required']"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="item.expDate"
              label="Expiry Date (MMYY)"
              dense
              outlined
              :rules="expiryRule"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="item.cvv"
              type="password"
              id="cvc"
              dense
              outlined
              autocomplete="off"
              label="Card Code (CVC)"
              :rules="cvcRule"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DeftDisclaimer />
          </v-col>
        </v-row>
        <v-row v-if="errorMessage">
          <v-col>
            <span style="color: red">{{ errorMessage }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="ma-2"
              :loading="loading"
              :disabled="loading"
              color="secondary"
              @click="validate"
            >
              <v-icon small left>mdi-check</v-icon>
              Process My Payment
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DeftDisclaimer from "./DEFTDisclaimer.vue";
import BaseService from "@/services/base.service.js";

export default {
  name: "creditCard",

  components: {
    DeftDisclaimer,
  },

  data() {
    return {
      loading: false,
      valid: true,
      item: {},
      errorMessage: "",
      amountRule: [(v) => !!v || "Payment Amount is required"],
      expiryRule: [
        (v) =>
          v
            ? /^(0[1-9]|1[0-2])([0-9]{4}|[0-9]{2})$/.test(v) ||
              "Use 2-digit MMYY format"
            : "Card expiration date is required",
      ],
      cvcRule: [
        (v) =>
          v
            ? /^[0-9]{3,4}$/.test(v) || "CVC should be a 3 or 4 digit number"
            : "CVC is required",
      ],
    };
  },

  computed: {
    ...mapGetters("policies", ["getPolicyDetails"]),
  },

  mounted() {
    var scripts = [
      "https://static.deft.com.au/cse/v1/crypto.min.js",
      "https://static.deft.com.au/cse/v1/apcpublickey.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    this.$set(this.item, "amount", this.getPolicyDetails.proRataCost);
  },

  methods: {
    ...mapMutations("policies", ["setIsPaymentComplete"]),

    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.proceedPayment();
      }
    },

    async getToken() {
      this.loading = true;

      let surchargePayload = {
        provider: "deft_macquarie",
        payment_method: "CARD",
        dbc: "123456",
        currency_code: "AUD",
        amount: this.item.amount,
        payment_frequency: "ONE_OFF",
        bin: this.item.cardNumber.substring(0, 6),
      };

      let service = new BaseService("payments/surcharge");
      this.errorMessage = "";

      let res = await service.create(surchargePayload);

      if (res.status === 200) {
        let surcharges = res.data?.data?.surcharge_amount;
        this.item.surcharge = surcharges ?? 0;
        this.item.policy_id = this.getPolicyDetails.id;
        this.item.total =
          parseFloat(this.item.amount) +
          parseFloat(this.item.surcharge.replace(/,/g, ""));

        this.setIsPaymentComplete(true);
        this.$emit("paymentProcessed", this.item);
      } else {
        this.errorMessage = res.data.message;
      }
      this.loading = false;
    },

    proceedPayment() {
      try {
        let encrypt = new Crypto();
        encrypt.setPublicKey(
          "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmqJMzKpsQzldpcdsZ3gdqWJE2tfbs9xSRaFabpWqbOi34UBr3FpsOHiy/t1c0Bf+SkOOLH/lq2wzjJYH7O66tfurXCrsr6QdXColmPBIzCBy9lnPMA1qfVS2XMRyGI8uptJe4VbGgQDjTZlHAtgaDwdiC7Xl//Ox1ROcWswjGD9IcZziID+2Qbd/u3HnBGxjLuNsvT7VGeubF3UwTsnZIgZKaPahhfRiL9HjmJ2FzlzI5d0RWhez3Xng0iPiGE0GjsTsFz1g9lLoqezF2rHeUWTnlLQV/PXilAmX3+nmdRNTqn4VV7ni3RSfeNznou1BrYrGmCBTuR+KWkGuzqW6EwIDAQAB"
        );

        let cardNumber = this.item.cardNumber.replace(/\s/g, "");

        this.item.cardNumberMask =
          cardNumber.substring(0, 6) +
          "****" +
          cardNumber.substring(cardNumber.length - 4, cardNumber.length);

        this.item.encodedCardNumber = encrypt.encrypt(cardNumber);

        this.getToken();
      } catch (err) {
        console.log("Error while encrypting the card");
      }
    },
  },
};
</script>
