<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      width="800"
      persistent
      v-model="dialog"
    >
      <v-toolbar flat dark :color="franchiseData.brand_background_color">
        <v-toolbar-title :style="{ color: franchiseData.brand_text_color }">
          <v-icon :color="franchiseData.brand_text_color">mdi-alert-box</v-icon>
          This is Important
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card class="pa-1">
        <v-stepper v-model="currentStep" elevation="0">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-10 font-small">
              <h3>Flood Inclusion</h3>
              <div class="space-10"></div>
              <p>Flood is currently excluded by your policy.</p>
              <div class="space-10"></div>
              <span style="text-decoration:underline"
                >Policy meaning of flood:</span
              >
              <br />
              “Flood” means the covering of normally dry land by water that has
              escaped or been released from the normal confines of any of the
              following:
              <div class="space-20"></div>
              <ol>
                <li class="mb-1">
                  A lake (whether or not it has been altered or modified)
                </li>
                <li class="mb-1">
                  A river (whether or not it has been altered or modified)
                </li>
                <li class="mb-1">
                  A creek (whether or not it has been altered or modified)
                </li>
                <li class="mb-1">
                  Another natural watercourse (whether or not it has been
                  altered or modified)
                </li>
                <li class="mb-1">A Reservoir</li>
                <li class="mb-1">A canal</li>
                <li class="mb-1">A dam</li>
              </ol>
              <div class="space-10"></div>
              Would you like a quotation to include Flood to your policy (extra
              premium will apply)?
              <div class="space-50"></div>
              <v-row>
                <v-col cols="11">
                  <v-row
                    class="justify-end"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      color="success"
                      :block="isSizeSmall"
                      @click="answeredYes('flood')"
                    >
                      Yes
                    </v-btn>

                    <v-btn
                      class="ml-3"
                      color="warning"
                      :block="isSizeSmall"
                      @click="answeredNo('flood')"
                    >
                      No
                    </v-btn>
                    <div class="space-50"></div>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2" class="font-small">
              <h3>Insurance History</h3>
              <div class="space-30"></div>
              <b>Previous Claims</b>
              <p>
                In the last five years have you had more than 3 claim and/or
                claims totalling more than $5,000?
              </p>
              <v-radio-group v-model="insuranceHistory[0]" row>
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
              <div class="space-10"></div>
              <b>Criminal Convictions</b>
              <p>
                Have you had any criminal convictions within the last 10 years?
              </p>
              <v-radio-group v-model="insuranceHistory[1]" row>
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
              <div class="space-10"></div>
              <b>Previous History</b>
              <p>
                Have you had any insurance declined, renewal refused or had
                special terms applied in the last 5 years?
              </p>
              <v-radio-group v-model="insuranceHistory[2]" row>
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
              <div class="space-10"></div>
              <p>
                Have you or any partner(s), shareholder(s) or director(s) of the
                business
                <br />
                - ever been declared bankrupt?
                <br />
                - ever been involved in a company or business which became
                insolvent or subject to any form of insolvency administration
                (e.g liquidation or receivership)?
              </p>
              <v-radio-group v-model="insuranceHistory[3]" row>
                <v-radio label="Yes" value="Yes"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
              <div class="space-20"></div>

              <v-row>
                <v-col cols="11">
                  <v-row
                    class="justify-end ml-3"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      color="success"
                      :disabled="insuranceHistory.length !== 4"
                      :block="isSizeSmall"
                      @click="answeredYes('history')"
                    >
                      Continue
                    </v-btn>
                    <div class="space-50"></div>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-10 font-small">
              <h3>Duty of Disclosure</h3>
              <div class="space-20"></div>
              <div class="duty-of-disclosure">
                a) Contracts of general insurance, other than eligible contracts
                <div class="space-10"></div>

                Your duty of disclosure
                <div class="space-10"></div>

                <p>
                  Before you enter into an insurance contract, you have a duty
                  to tell us anything that you know, or could reasonably be
                  expected to know, may affect our decision to insure you and on
                  what terms.
                </p>

                <div class="space-10"></div>
                You have this duty until we agree to insure you.
                <div class="space-10"></div>

                You have the same duty before you renew, extend, vary or
                reinstate an insurance contract.
                <div class="space-10"></div>

                You do not need to tell us anything that:
                <div class="space-10"></div>

                <ul>
                  <li>reduces the risk we insure you for or</li>
                  <li>is common knowledge or</li>
                  <li>we know or should know as an insurer or</li>
                  <li>we waive your duty to tell us about.</li>
                </ul>

                <div class="space-10"></div>
                If you do not tell us something
                <div class="space-10"></div>
                If you do not tell us anything you are required to, we may
                cancel your contract or reduce the amount we will pay you if you
                make a claim, or both.
                <div class="space-10"></div>
                If your failure to tell us is fraudulent, we may refuse to pay a
                claim and treat the contract as if it never existed.

                <div class="space-20"></div>
                <v-divider></v-divider>
                <div class="space-20"></div>

                b) New eligible (i.e. Retail) contracts of insurance Your duty
                of disclosure
                <div class="space-10"></div>
                Your duty of disclosure
                <div class="space-10"></div>
                Before you enter into an insurance contract, you have a duty of
                disclosure under the Insurance Contracts Act 1984.
                <div class="space-10"></div>
                If we ask you questions that are relevant to our decision to
                insure you and on what terms, you must tell us anything that you
                know and that a reasonable person in the circumstances would
                include in answering the questions.
                <div class="space-10"></div>
                You have this duty until we agree to insure you.
                <div class="space-10"></div>
                If you do not tell us something
                <div class="space-10"></div>
                If you do not tell us anything you are required to tell us, we
                may cancel your contract or reduce the amount we will pay you if
                you make a claim, or both.
                <div class="space-10"></div>
                If your failure to tell us is fraudulent, we may refuse to pay a
                claim and treat the contract as if it never existed.
                <div class="space-20"></div>
                <v-divider></v-divider>
                <div class="space-20"></div>
                c) Renewal of eligible (i.e. Retail) contracts of insurance
                <div class="space-10"></div>
                Your duty of disclosure
                <div class="space-10"></div>
                Before you renew this contract of insurance, you have a duty of
                disclosure under the Insurance Contracts Act 1984.
                <div class="space-10"></div>
                If we ask you questions that are relevant to our decision to
                insure you and on what terms, you must tell us anything that you
                know and that a reasonable person in the circumstances would
                include in answering the questions.
                <div class="space-10"></div>
                Also, we may give you a copy of anything you have previously
                told us and ask you to tell us if it has changed. If we do this,
                you must tell us about any change or tell us that there is no
                change.
                <div class="space-10"></div>
                If you do not tell us about a change to something you have
                previously told us, you will be taken to have told us that there
                is no change.
                <div class="space-10"></div>
                You have this duty until we agree to renew the contract.
                <div class="space-10"></div>
                If you do not tell us something
                <div class="space-10"></div>
                If you do not tell us anything you are required to tell us, we
                may cancel your contract or reduce the amount we will pay you if
                you make a claim, or both.
                <div class="space-10"></div>
                If your failure to tell us is fraudulent, we may refuse to pay a
                claim and treat the contract as if it never existed.
                <div class="space-20"></div>
                <v-divider></v-divider>
                <div class="space-20"></div>
                <h3>General Advice</h3>
                <div class="space-10"></div>
                I confirm that I have read the Financial Services Guide and
                understand that National Franchise Insurance Brokers have not
                taken into account my/our individual objectives, financial
                situations or needs. I confirm I/we have read the product
                disclosure statement and I/we have considered the
                appropriateness of the advice taking into account my/our
                personal circumstances.
                <div class="space-20"></div>
                <v-divider></v-divider>
                <div class="space-20"></div>
                <h3>Declaration</h3>
                I declare and warrant that the answers given above are in every
                aspect true and correct and that I/we have not withheld any
                information within my/our knowledge likely to affect the
                decision of the Insurer to my/our eligibility for insurance. I
                have read and understood the above application and declaration
                shall be the basis of the contract between the Insurer and I/us.
                <div class="space-20"></div>
                <v-divider></v-divider>
                <div class="space-10"></div>
                <v-checkbox
                  v-model="dod.disclosure"
                  hide-details
                  label="I have read and understood the above statements. I have read the Product Disclosure Statement, Privacy Statement and Financial Services Guide , and I agree to answer all questions in accordance with my duty of disclosure."
                ></v-checkbox>
                <v-checkbox
                  v-model="dod.advice"
                  hide-details
                  label="I have read and agree with the general advice statement above."
                ></v-checkbox>
                <v-checkbox
                  v-model="dod.declaration"
                  hide-details
                  label="I have read and agree with the declaration above."
                ></v-checkbox>
              </div>
              <div class="space-20"></div>

              <v-row>
                <v-col cols="11">
                  <v-row
                    class="justify-end"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      :style="{ color: franchiseData.brand_text_color }"
                      :color="franchiseData.brand_background_color"
                      @click="validate"
                      :block="isSizeSmall"
                    >
                      {{
                        proceedLoading ? "Processing...Please wait" : "Proceed"
                      }}
                    </v-btn>
                    <div class="space-50"></div>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <AdditionalInsuranceDialog
      :dialog="additionalInsuranceDialog"
      :type="additionalDialogType"
      @dialogClosed="additionalInsuranceDialog = false"
      @answeredYesInAdditionalDialog="answeredYesInAdditionalDialog"
    />
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FranchiseMixin from "@/mixins/franchise-style";
import Snackbar from "@/components/common/Snackbar";
import BaseService from "@/services/base.service.js";
import AdditionalInsuranceDialog from "./AdditionalInsuranceDialog.vue";

export default {
  name: "CoverageDialogs",
  props: {
    dialog: Boolean,
    prodInfo: Object,
    referralSum: Array,
  },
  components: {
    Snackbar,
    AdditionalInsuranceDialog,
  },
  mixins: [FranchiseMixin],
  data() {
    return {
      currentStep: "1",
      agreements: {},
      dod: {},
      snackbar: {},
      proceedLoading: false,
      additionalInsuranceDialog: false,
      additionalDialogType: "",
      insuranceHistory: [],
      historyQuestion: [
        "In the last five years have you had more than 3 claim and/or claims totalling more than $5,000?",
        "Have you had any criminal convictions within the last 10 years?",
        "Have you had any insurance declined, renewal refused or had special terms applied in the last 5 years?",
        "Have you or any partner(s), shareholder(s) or director(s) of the business, ever been declared bankrupt?, ever been involved in a company or business which became insolvent or subject to any form of insolvency administration (e.g liquidation or receivership)?",
      ],
    };
  },
  computed: {
    ...mapGetters("franchisee", ["getFranchiseeData"]),

    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
  },
  mounted() {
    if (this.prodInfo.product_id === 45) {
      this.currentStep = "2";
    }
  },
  methods: {
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    async confirm() {
      let isApprovedForPayment = this.referralSum.length === 0;
      let payload = {
        referral_questions: {
          flood: [],
          history: [],
        },
        referral_sum: this.referralSum,
        template_id: this.prodInfo.template_id,
        product_id: this.prodInfo.product_id,
        franchisee_id: this.getFranchiseeData.id,
      };

      Object.keys(this.agreements).forEach((key) => {
        if (this.agreements[key] == true) {
          isApprovedForPayment = false;

          if (key === "flood") {
            payload.referral_questions.flood.push({
              question:
                "Would you like a quotation to include Flood to your policy (extra premium will apply)?",
              answer: "Yes",
            });
          } else {
            this.insuranceHistory.forEach((item, index) => {
              if (item === "Yes") {
                payload.referral_questions.history.push({
                  question: this.historyQuestion[index],
                  answer: "Yes",
                });
              }
            });
          }
        }
      });

      try {
        if (
          payload.referral_questions.flood.length ||
          payload.referral_questions.history.length ||
          payload.referral_sum.length
        ) {
          let service = new BaseService("referrals");

          await service.create(payload);
        }

        if (isApprovedForPayment) {
          this.$emit(`confirmed`);
        } else {
          this.$router.push(`/franchisee/customise-insurance/thank-you`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.proceedLoading = false;
      }
    },
    answeredNo(type) {
      this.agreements[type] = false;
      this.currentStep++;
    },
    validate() {
      if (this.proceedLoading) {
        return;
      }
      if (!this.dod.declaration || !this.dod.disclosure || !this.dod.advice) {
        this.snackbar = {
          show: true,
          color: "red",
          text: `Please Agree to all the conditions in the Disclosure agreement`,
        };
        this.resetSnackbar();
      } else {
        this.proceedLoading = true;
        this.confirm();
      }
    },
    answeredYes(type) {
      if (type === "flood") {
        this.additionalInsuranceDialog = true;
        this.additionalDialogType = type;
      } else {
        this.agreements[type] = this.insuranceHistory.some(
          (item) => item === "Yes"
        );
        this.currentStep++;
      }
    },
    answeredYesInAdditionalDialog(type) {
      this.agreements[type] = true;
      this.additionalInsuranceDialog = false;
      this.currentStep++;
    },
    navigateToPreviousStep() {
      let stepNo = parseInt(this.currentStep);
      stepNo -= 1;
      this.currentStep = `${stepNo}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.duty-of-disclosure {
  height: 400px;
  overflow-y: scroll;
}
.font-small {
  font-size: 14px !important;
}
</style>
