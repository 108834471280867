<template>
  <div>
    <v-container fluid v-if="!isPaymentComplete">
      <v-row
        ><v-col>
          <p style="font-size">
            <span>Purchase Your Insurance (BPAY).</span><br /><br />
            <span
              >A remittance slip can be generated and emailed to you so an
              offline payment can be made. Available offline payment methods
              include credit card payments via telephone, BPAY and Australia
              Post BillPay. If you would like to take advantage of one of these
              options, please click the button below.</span
            ><br /><br />
            <strong
              >Please note: once selected you will not be able to select the
              direct payment or finance options.</strong
            >
          </p></v-col
        ></v-row
      >
      <v-row>
        <v-col>
          <v-btn class="ma-2" color="secondary" @click="dialog = 'true'">
            <v-icon small left>mdi-check</v-icon>
            Email Remittance Slip
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <p>
        An BPAY payment slip has been emailed to you.<br /><br />
        Please ensure payment is made prior to {{ date }} to ensure continuous
        coverage
      </p>
      <!-- <v-row>
        <v-col>
          <router-link :to="{ path: `/policies/${getPolicyDetails.id}` }"
            >View Policy</router-link
          >
        </v-col>
      </v-row> -->
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Notice
        </v-card-title>
        <v-card-text
          >The due date for this payment is {{ date }}.<br /><br />
          As this date is after one or more of your policies has commenced, a
          cover note will be in place for any applicable policies for 7 days
          from the commencement date of the policy. Payment must be made within
          this time or the insurance cover will expire.<br /><br />If you accept
          this and wish to proceed, please check the box below and click
          continue.<br /><br />

          <v-checkbox v-model="agreed" :label="agreeText"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="proceed"
            :disabled="!agreed"
            :loading="loading"
          >
            Continue
          </v-btn>
          <v-btn
            color="danger"
            text
            @click="dialog = false"
            :disabled="loading"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BaseService from "@/services/base.service.js";
import moment from "moment";

export default {
  name: "BPay",

  props: ["policyId", "email"],

  data() {
    return {
      isPaymentComplete: false,
      errorMessage: "",
      dialog: false,
      agreed: false,
      loading: false,
      date: moment(new Date())
        .add(7, "days")
        .format("DD-MM-YYYY"),
      agreeText:
        "I agree that cover will be in place for a period of 7 days from the commencement date and will expire if payment is not made after this time.",
    };
  },

  computed: {
    ...mapGetters("policies", ["getPolicyDetails"]),
  },

  methods: {
    ...mapMutations("policies", ["setIsPaymentComplete"]),

    async proceed() {
      this.loading = true;
      let payload = {
        provider: "bpay",
        currency_code: "AUD",
        total_amount: parseFloat(this.getPolicyDetails.proRataCost),
        policy_id: this.getPolicyDetails.id,
        recipient_email: this.email,
      };

      let service = new BaseService("payments");

      let res = await service.create(payload);

      if (res.status === 200 && res?.data?.data?.status === "APPROVED") {
        this.$emit("paymentProcessed");
        this.isPaymentComplete = true;
        this.setIsPaymentComplete(true);
        this.errorMessage = "";
        this.dialog = false;
      } else {
        this.errorMessage = res.data.error_message;
        this.isPaymentComplete = false;
      }
      this.loading = false;
    },
  },
};
</script>
