<template>
  <div class="pa-5">
    <v-breadcrumbs
      class="pa-0 mt-n5"
      :items="breadcrumbs"
      divider="-"
    ></v-breadcrumbs>
    <div class="space-30"></div>
    <v-row>
      <v-col cols="9">
        <p>Insurance Policy - Preview</p>
      </v-col>
      <v-col cols="3">
        <!-- <v-btn outlined text class="mr-5" > <v-icon class="mr-1"  small>mdi-pencil </v-icon>  Edit</v-btn> -->
        <v-btn outlined text @click="reviewPolicyClicked()">
          <v-icon class="mr-1" small>mdi-download </v-icon>Review Policy
          Schedule</v-btn
        >
      </v-col>
    </v-row>
    <div class="space-30"></div>
    <div class="space-30"></div>
    <template class="action-btns">
      <v-btn
        :color="isFranchisor ? franchiseData.brand_background_color : 'success'"
        :style="isFranchisor ? { color: franchiseData.brand_text_color } : ''"
        :block="isSizeSmall"
        @click="buyNow"
      >
        Buy Now
      </v-btn>

      <v-btn text :block="isSizeSmall" @click="backToHome">
        Cancel
      </v-btn>
    </template>

    <CoverageDialogs :dialog="coverageDialog" @confirmed="policyConfirmed" />
    <PaymentDialog
      :dialog="paymentDialog"
      :cost="coveragePremiums.proRata_cost"
      @cancelled="paymentDialog = false"
    />
    <v-dialog v-model="isShowPreview" persistent max-width="1000">
      <v-card>
        <v-card-title class="text-h5">
          Review Policy Schedule
        </v-card-title>
        <v-card-text class="reviewCard">
          <v-card v-if="showDalogSkeleton">
            <v-skeleton-loader
              v-bind="$attrs"
              type="table-heading, list-item-two-line, image"
            ></v-skeleton-loader>
          </v-card>
          <iframe ref="iframe" width="100%" height="100%" frameborder="1">
          </iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowPreview = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FranchiseMixin from "@/mixins/franchise-style";
import PaymentDialog from "@/components/franchisees/insurance-coverage/popups/PaymentDialog";
import CoverageDialogs from "./popups/CoverageDialogs";
import PolicyService from "@/services/policy.service.js";

export default {
  name: "PreviewPolicy",
  mixins: [FranchiseMixin],
  components: {
    PaymentDialog,
    CoverageDialogs,
  },
  data() {
    return {
      coveragePremiums: {},
      coverageDialog: false,
      isShowPreview: false,
      paymentDialog: false,
      showDalogSkeleton: false,
      breadcrumbs: [
        {
          id: 1,
          text: "Customize Insurance",
          href: "#",
        },
        {
          id: 2,
          text: "Step 3",
          href: "#",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("policies", ["getPolicyDetails"]),

    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
  },
  methods: {
    buyNow() {
      this.coverageDialog = true;
    },
    backToHome() {
      this.$router.push(`/mod`);
    },
    policyConfirmed() {
      this.paymentDialog = true;
      this.coverageDialog = false;
    },
    async reviewPolicyClicked() {
      try {
        this.isShowPreview = true;
        this.showDalogSkeleton = true;
        let res = await PolicyService.getPolicySchedule(
          this.getPolicyDetails.id
        );

        const iFrameEl = this.$refs.iframe;
        const doc = iFrameEl.contentWindow.document;
        doc.open();
        doc.write(res.data);
        doc.close();

        iFrameEl.onload = () => {
          iFrameEl.style.height = doc.body.scrollHeight + 500 + "px";
        };

        this.showDalogSkeleton = false;
      } catch (error) {
        console.error(
          "🚀 ~ file: InsuranceCoverage.vue ~ line 388 ~ getActiveTemplate ~ error",
          error
        );
        this.$store.dispatch(
          "common/setFlashMsg",
          "Some error occured. Please try again later"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reviewCard {
  max-height: 60vh;
  overflow-x: auto;
}
</style>
