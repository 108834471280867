import axios from "axios";
import authHeader from "./auth-header";
import BaseService from "./base.service";

class PaymentService extends BaseService {
  constructor(url) {
    super(url);
  }

  updatePFPaymentDetails(payload) {
    return axios.put(this.url + `/premium/update`, payload, {
      headers: authHeader(this.token),
    });
  }
}

export default new PaymentService("payments");
