<template
  ><div>
    <v-container fluid v-if="!isPaymentComplete">
      <v-row
        ><v-col>
          <p style="font-size">
            <span>Purchase Your Insurance (Premium Funding).</span><br /><br />
            <span>
              By selecting the Premium funding option, it allows approved
              clients to pay for their insurance policy in easy to manage
              monthly instalment and avoid the larger upfront payment.</span
            ><br /><br />
            <span
              >Premium funding is a commercial loan contract between the client
              (you) and a Third party provider.</span
            ><br /><br />
            <span>All Terms & Conditions are explained when you proceed.</span
            ><br /><br /><span>
              Please note this option will attract some additional fees</span
            >
          </p></v-col
        ></v-row
      >
      <v-row>
        <v-col>
          <v-btn
            class="ma-2"
            color="secondary"
            @click="getQoute"
            :loading="quoteLoading"
          >
            <v-icon small left>mdi-check</v-icon>
            Get Quote
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="hasQuote">
      <v-row>
        <strong>Quote generated!</strong>
      </v-row>
      <v-row>
        <v-col>
          <span>Loan Number : {{ item.loan_number }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Amount Financed : $ {{ item.amount_financed }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Flat Interest Rate : {{ item.flat_interestrate }}%</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Total Interest Charges : $ {{ item.total_interestcharges }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Total Repayable amount: $ {{ item.total_amountrepayable }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Payment Frequency: Monthly</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Number of Installments: {{ item.number_ofinstalments }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>First Installment Date: {{ item.first_instalmentdate }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Last Installment Date: {{ item.last_instalmentdate }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Initial Installment Amount: $
            {{ item.initial_instalmentamount }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Ongoing Installment Amount: $
            {{ item.ongoing_instalmentamount }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Application Fee: $ {{ item.application_fee }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Cancellation Fee: $ {{ item.cancellation_fee }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Dishonour Fee: $ {{ item.dishonour_fee }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Credit Card Surcharge (per payment):</span>
          <ul>
            <li v-for="credit in item.credit_surcharge" :key="credit.card_type">
              <strong>{{ credit.card_type }}</strong> -
              {{ credit.surcharge_percent }} %
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong>Funded Policies</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Insurer: {{ getFranchiseeData.name }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Class: BI</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Policy Number: {{ getPolicyDetails.id }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Inception Date:
            {{ getPolicyDetails.start_dt | formattedExpiryDate }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            >Expiry Date:
            {{ getPolicyDetails.expiry_dt | formattedExpiryDate }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span>Premium: $ {{ getPolicyDetails.proRataCost }}</span>
        </v-col>
      </v-row>
      <br /><br />
      <v-row>
        <v-col>
          <strong>Terms and Conditions</strong>
        </v-col>
      </v-row>
      <v-row class="tnC">
        <v-col>
          <span v-html="terms"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="agreedFirst"
            :label="labelAgreementOne"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong
            >IMPORTANT: You should only check the box for this declaration if
            this loan is wholly or predominantly for business purposes. By
            checking the box for this declaration you may lose your protection
            under the National Credit Code.</strong
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="agreedSecond"
            :label="labelAgreementTwo"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong
            >Please note that failure to pay your loan may result in your
            insurance policy being cancelled.</strong
          >
        </v-col>
      </v-row>
      <template v-if="agreedSecond && agreedFirst">
        <br />
        <hr />
        <v-row>
          <v-col>
            <span
              >By completing the credit card details below, the Borrower
              requests IQumulate Premium Funding Pty Ltd (IQPF), until further
              notice in writing, to debit the nominated credit card below any
              amounts which IQFS (User ID 536512) may debit or charge me, in
              accordance with clause 14 of the Premium Funding Conditions.<br /><br />
              Initial Payment will be debited now, subsequent instalments will
              be debited each month on your instalment date.<br /><br />
              A credit card surcharge of 0.95% for VISA or MasterCard 2.16% for
              American Express will be incurred for each credit card transaction
              processed.</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <strong>DISCLAIMER: </strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <strong
              >IQumulate Funding Services Pty Limited ABN 49 632 439 902
              ('IQFS') is the premium funder and the premium funding services
              are provided by IQumulate Premium Funding Pty Limited ABN 82 127
              517 677 ('IQPF'). IQPF is not an authorised deposit-taking
              institution for the purposes of the Banking Act (Cth) 1959, and
              IQPF's obligations do not represent deposits or other liabilities
              of IQFS. IQFS does not guarantee or otherwise provide assurance in
              respect of the obligations of IQPF.</strong
            >
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col>
                <!-- <iframe :src="iframeSrc" width="100%" height="600" frameborder="0">
            </iframe> -->
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="totalAmt"
                      label="Payment Amount"
                      readonly
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.loan_number"
                      label="Loan Number"
                      readonly
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.account_name"
                      dense
                      outlined
                      label="Account Name"
                      :rules="[(v) => !!v || 'Account name is required']"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="item.bsb"
                      label="BSB"
                      dense
                      outlined
                      :rules="[(v) => !!v || 'BSB is required']"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="item.account_number"
                      type="number"
                      dense
                      outlined
                      label="Account Number"
                      :rules="[(v) => !!v || 'Account Number is required']"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      class="ma-2"
                      color="secondary"
                      @click="validate"
                      :loading="paymentLoading"
                    >
                      <v-icon small left>mdi-check</v-icon>
                      Process My Payment
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BaseService from "@/services/base.service.js";
import PaymentService from "@/services/payment.service.js";
import moment from "moment";

export default {
  name: "PremiumFunding",

  props: ["email", "policy_inception_date", "policy_expiry_date"],

  data() {
    return {
      isPaymentComplete: false,
      errorMessage: "",
      item: {},
      terms: {},
      labelAgreementOne:
        "I acknowledge and agree that I have read and understood the terms set out above, including the Direct Debit Service Agreement, and agree to be bound by them. I also confirm that I am truly authorised to be to execute this document on behalf of the Borrower.",
      labelAgreementTwo:
        "I declare that the credit to be provided to me by IQumulate Funding Services Pty Ltd is to be applied wholly or predominantly for business purposes.",
      agreedSecond: false,
      agreedFirst: false,
      accountNumber: "84101930",
      dlValue: "ppfpay",
      env: "demo",
      quoteLoading: false,
      valid: true,
      paymentLoading: false,
      hasQuote: false,
    };
  },

  computed: {
    ...mapGetters("franchisee", ["getFranchiseeData", "getSelectedSituation"]),
    ...mapGetters("policies", ["getPolicyDetails"]),

    successURL() {
      return btoa(process.env.VUE_APP_URL + "/payment-pf/status/APPROVED");
    },

    failureURL() {
      return btoa(process.env.VUE_APP_URL + "/payment-pf/status/FAILURE");
    },

    serverURL() {
      return btoa(
        process.env.VUE_APP_API_BASE_URL +
          "/payments/premium/quotes/transactions"
      );
    },

    todaysDay() {
      return new Date().toISOString().slice(0, 10);
    },

    totalAmt() {
      return this.getPolicyDetails.proRataCost
        .toString()
        .replace(/[^\w\s]/gi, "");
    },

    iframeSrc() {
      return `https://${this.env}.ippayments.com.au/access/index.aspx?a=${this.accountNumber}&dl=${this.dlValue}&amount=${this.totalAmt}&custnumber=${this.item.loan_number}&UserApprovedURL=${this.successURL}&UserDeclinedURL=${this.failureURL}&ServerURL=${this.serverURL}`;
    },
  },

  methods: {
    ...mapMutations("policies", ["setIsPaymentComplete"]),

    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.proceedPayment();
      }
    },

    async proceedPayment() {
      let payload = {
        loan_number: this.item.loan_number,
        first_payment_method: "CC",
        ongoing_payment_method: "DD",
        account_name: this.item.account_name,
        bsb: this.item.bsb,
        account_number: this.item.account_number,
        amount: parseFloat(this.totalAmt),
      };

      try {
        this.paymentLoading = true;
        await PaymentService.updatePFPaymentDetails(payload);
        this.$router.push("/payment-pf/status/APPROVED");
      } catch (e) {
        this.$router.push("/payment-pf/status/FAILURE");
      } finally {
        this.paymentLoading = false;
      }
    },

    async getQoute() {
      this.quoteLoading = true;
      let name = this.getFranchiseeData.contact.person_name.split(" ");

      let payload = {
        broker: {
          // user_name: "nfib_mppo_prelive",
          // user_password: "Prel!veT3st",
          broker_contact_email: "info@mynfib.com.au",
          affinity_scheme_code: process.env.affinity_scheme_code ?? "PSCTEST",
        },
        client: {
          legal_name: this.getFranchiseeData.name,
          trading_name: this.getFranchiseeData.trading_name,
          abn: this.getFranchiseeData.abn_acn,
          street_address: {
            street_line1: this.getSelectedSituation.address?.address1,
            street_line2: this.getSelectedSituation.address?.address2,
            suburb: this.getSelectedSituation.address?.city_text,
            state: this.getSelectedSituation.address?.state.descr,
            post_code: this.getSelectedSituation.address?.post_code,
          },
          postal_address: {
            street_line1: this.getSelectedSituation.address?.address1,
            street_line2: this.getSelectedSituation.address?.address2,
            suburb: this.getSelectedSituation.address?.city_text,
            state: this.getSelectedSituation.address?.state.descr,
            post_code: this.getSelectedSituation.address?.post_code,
          },
          mobile_number: this.getFranchiseeData.contact?.mobile?.num,
          telephone_number: this.getFranchiseeData.billing_contact?.phone?.num,
          fax_number: "",
          email: this.getFranchiseeData.billing_contact?.email,
          title: "",
          first_name: name[0],
          last_name: name[name.length - 1],
          entity_type: "Company",
          broker_client_reference: "111111",
        },
        policies: [
          {
            policy_number: this.getPolicyDetails?.id?.toString(),
            invoice_number: this.getPolicyDetails?.id?.toString(),
            policy_class_code: "BI",
            policy_under_writer_code: "AGU",
            policy_inception_date: this.toYMD(this.getPolicyDetails.start_dt),
            policy_expiry_date: this.toYMD(this.getPolicyDetails.expiry_dt),
            // policy_inception_date: moment(
            //   this.getPolicyDetails.start_dt
            // ).format("YYYY-MM-DD"),
            // policy_expiry_date: moment(this.getPolicyDetails.expiry_dt).format(
            //   "YYYY-MM-DD"
            // ),
            policy_amount: parseFloat(this.totalAmt),
            deft_reference_number: "40470783913153777",
          },
        ],
        first_instalment_date: moment(new Date())
          .add(15, "days")
          .format("YYYY-MM-DD"),
        number_of_instalments: 10,
        commission_rate: 0.022,
        settlement_days: 3,
        payment_frequency: "M",
      };

      let service = new BaseService("payments/premium/qoute");

      let res = await service.create(payload);

      if (res.status === 200 && res.data.loan_number) {
        this.item = res.data;
        this.hasQuote = true;
        await this.getTerms();
        // this.isPaymentComplete = true;
        // this.errorMessage = "";
        // await this.savePFResponse();
        // this.setIsPaymentComplete(true);
      } else {
        this.hasQuote = false;
        this.errorMessage = res.data.error_message;
        this.$emit("edit_error", this.errorMessage);
        this.isPaymentComplete = false;
      }
      this.quoteLoading = false;
    },
    async savePFResponse() {
      this.$emit("paymentProcessed");
      let service = new BaseService("payments");

      let payload = {
        provider: "premium_funding",
        currency_code: "AUD",
        amount: parseFloat(this.totalAmt),
        invoice_number: this.getPolicyDetails?.id?.toString(),
        recipient_email: this.getFranchiseeData.billing_contact?.email,
        policy_id: this.getPolicyDetails?.id?.toString(),
        loan_number: this.item.loan_number,
        transaction_result: "APPROVED",
      };

      await service.create(payload);
    },
    async getTerms() {
      let service = new BaseService("payments/premium/terms");

      let res = await service.get_all();

      if (res.status === 200) {
        this.terms = res.data.terms;
      }
    },
    toYMD(dt) {
      return dt.length >= 10 ? dt.substr(0, 10) : dt;
    },
  },

  filters: {
    formattedExpiryDate(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
};
</script>

<style>
.tnC {
  max-height: 300px;
  overflow: auto;
  border: 1px solid #c8c8ca;
}
</style>
