<template>
  <div class="mx-4 my-2">
    Please select a payment option:
    <v-row>
      <v-col cols="12" lg="2" md="2">
        <v-radio-group v-model="paymentOption">
          <v-radio
            :disabled="disableCC"
            label="Credit Card"
            value="creditCard"
          ></v-radio>
          <v-radio
            :disabled="disablePF"
            label="Premium Funding"
            value="premiumFunding"
          ></v-radio>
          <v-radio :disabled="disableBPay" label="BPAY" value="bpay"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" offset="1" lg="9" md="9">
        <PaymentConfirmation
          v-if="showConfirmPage"
          :item="item"
          :email="currentUser.email"
          @cancelPayment="cancelPayment"
        />
        <div v-else>
          <CreditCardPayment
            v-if="paymentOption === 'creditCard'"
            @paymentProcessed="gotToConfirmPage"
          />
          <BPay
            v-if="paymentOption === 'bpay'"
            :email="currentUser.email"
            @paymentProcessed="disabledPaymentSelection(true, true, false)"
          />
          <PremiumFunding
            v-if="paymentOption === 'premiumFunding'"
            :email="currentUser.email"
            @paymentProcessed="disabledPaymentSelection(true, false, true)"
            @edit_error="showSnackBar($event)"
          />
        </div>
      </v-col>
    </v-row>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import CreditCardPayment from "./CreditCardPayment.vue";
import PaymentConfirmation from "./PaymentConfirmation.vue";
import BPay from "./BPay.vue";
import PremiumFunding from "./PremiumFunding.vue";
import Snackbar from "@/components/common/Snackbar";

export default {
  name: "payment",
  components: {
    BPay,
    CreditCardPayment,
    PaymentConfirmation,
    PremiumFunding,
    Snackbar,
  },
  data() {
    return {
      paymentOption: "",
      item: {},
      showConfirmPage: false,
      snackbar: {},
      disableCC: false,
      disablePF: false,
      disableBPay: false,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    cancelPayment() {
      this.showConfirmPage = false;
      this.disabledPaymentSelection(false, false, false);
    },

    disabledPaymentSelection(cc, pf, bpay) {
      this.disableCC = cc;
      this.disablePF = pf;
      this.disableBPay = bpay;
    },

    gotToConfirmPage(payload) {
      this.item = payload;
      this.showConfirmPage = true;
      this.disabledPaymentSelection(false, true, true);
    },

    showSnackBar(errorMsg) {
      this.snackbar = {
        show: true,
        text: errorMsg ?? "An error occurred, Please try again",
        color: `red`,
      };
      this.resetSnackbar();
    },

    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
  },

  created() {
    this.disablePF = (process.env.VUE_APP_PAYMENT_PF_ENABLED ?? true) == false
  }
};
</script>
