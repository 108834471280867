<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          flat
          dark
          :color="franchiseData.brand_background_color"
          :style="{ color: franchiseData.brand_text_color }"
        >
          <v-btn icon dark @click="cancelled">
            <v-icon :color="franchiseData.brand_text_color">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Payment Options</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <PaymentOption class="pa-10" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentOption from "@/components/payment/Payment";
import FranchiseMixin from "@/mixins/franchise-style";

export default {
  name: "PaymentDialog",
  props: {
    dialog: Boolean,
  },
  mixins: [FranchiseMixin],
  components: {
    PaymentOption,
  },
  methods: {
    cancelled() {
      this.$emit(`cancelled`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
