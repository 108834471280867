<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">
          Please Confirm
        </v-card-title>
        <v-card-text
          >You have selected “YES” to an
          {{
            type === "flood"
              ? "optional flood cover quote"
              : "Insurance history cover"
          }}, by selecting proceed your details will be sent to your specialist
          broker who will obtain an alternative quote and contact you.
          <br /><br />
          Please note: By adding
          {{
            type === "flood"
              ? "optional flood cover quote"
              : "Insurance history cover"
          }}
          to your insurance coverage the price of your insurance will increase.
          <br /><br />
          If you have selected by mistake then please select the cancel button
          to return and click No to continue</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('dialogClosed')">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('answeredYesInAdditionalDialog', type)"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AdditionalInsuranceDialog",
  props: {
    type: String,
    dialog: Boolean,
  },
  data() {
    return {};
  },
};
</script>
