import axios from "axios";
import authHeader from "./auth-header";
import BaseService from "./base.service";

class PolicyService extends BaseService {
  constructor(url) {
    super(url);
  }

  getPolicySchedule(id) {
    return axios.get(`${this.url}/${id}/schedule?format=html`, {
      headers: authHeader(this.token),
    });
  }

  downloadPolicySchedule(id) {
    return axios.get(`${this.url}/${id}/schedule`, {
      headers: authHeader(this.token),
    });
  }
}

export default new PolicyService("policies");
