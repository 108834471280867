<template>
  <div>
    <v-container v-if="!isPaymentComplete" fluid>
      <v-row>
        <v-col>
          <v-text-field
            v-model="item.name"
            label="Card Holder Name"
            readonly
            dense
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="modifiedCardNumber"
            label="Card Number"
            filled
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="item.expDate"
            label="Expiry Date (MMYY)"
            filled
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="item.amount"
            label="Payment Amount"
            filled
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="item.surcharge"
            label="Surcharge"
            filled
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="item.total"
            label="Total"
            filled
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DeftDisclaimer />
        </v-col>
      </v-row>
      <v-row v-if="errorMessage">
        <v-col>
          <span style="color: red">{{ errorMessage }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            :loading="loading"
            :disabled="loading"
            class="ma-2"
            color="secondary"
            @click="confirmPayment"
          >
            <v-icon small left>mdi-check</v-icon>
            Confirm Payment
          </v-btn>
        </v-col>
        <v-col>
          <v-btn class="ma-2" outlined @click="cancelPayment">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <template v-if="paymentDetail.status === 'APPROVED'">
        <v-row>
          <strong>Payment Successful!</strong>
        </v-row>
        <v-row>
          <v-col>
            <span>Amount : {{ item.amount }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Surcharge : {{ item.surcharge }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Payment Amount : {{ paymentDetail.amount }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Card Number : {{ modifiedCardNumber }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Expiry Date: {{ item.expDate }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Receipt Number: {{ paymentDetail.response_ref }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Payment Time: {{ paymentDetail.dt | dateFiltered }}</span>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <router-link :to="{ path: `/policies/${this.item.policy_id}` }"
              >View Policy</router-link
            >
          </v-col>
        </v-row> -->
      </template>
    </v-container>
  </div>
</template>

<script>
import DeftDisclaimer from "./DEFTDisclaimer.vue";
import BaseService from "@/services/base.service.js";
import moment from "moment";

export default {
  name: "paymentConfirmation",

  components: {
    DeftDisclaimer,
  },

  props: ["item", "email"],

  data() {
    return {
      isPaymentComplete: false,
      paymentDetail: {},
      errorMessage: "",
      loading: false,
    };
  },

  computed: {
    modifiedCardNumber() {
      return `XXXXXXXXXXXX${this.item.cardNumber.substring(
        this.item.cardNumber.length - 4,
        this.item.cardNumber.length
      )}`;
    },
  },

  methods: {
    async confirmPayment() {
      this.loading = true;
      let payload = {
        provider: "deft_macquarie",
        drn: "string",
        currency_code: "AUD",
        amount: parseFloat(this.item.amount),
        fee: 0,
        surcharge_amount: parseFloat(this.item.surcharge.replace(/,/g, "")),
        total_amount: parseFloat(this.item.total),
        recipient_email: this.email,
        payment_frequency: "ONE_OFF",
        customer_reference: "string",
        secure_token: "",
        inject_fee_and_surcharge: true,
        dbc: 0,
        crn: 0,
        customer_transaction_id: "string",
        card_details: {
          card_expiry_date: this.item.expDate,
          card_number_mask: this.item.cardNumberMask,
          encoded_card_number: this.item.encodedCardNumber,
          card_holder_name: this.item.name,
        },
        policy_id: this.item.policy_id,
      };

      let service = new BaseService("payments");

      let res = await service.create(payload);

      if (res.status === 200 && res?.data?.data?.status === "APPROVED") {
        this.paymentDetail = res?.data?.data;
        this.isPaymentComplete = true;
        this.errorMessage = "";
      } else {
        this.errorMessage = res.data.error_message;
        this.isPaymentComplete = false;
      }
      this.loading = false;
    },

    cancelPayment() {
      this.$emit("cancelPayment");
    },
  },

  filters: {
    dateFiltered(val) {
      let date = moment(val);
      if (val && date.isValid() && val.length > 10) {
        return moment(val).format("DD-MM-YYYY hh:mm A");
      } else {
        return val;
      }
    },
  },
};
</script>
